<template>
  <v-data-table :headers="headers" :items="items" sort-by="order" :items-per-page="5" class="mb-5">
      <template v-slot:top>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn color="primary" class="mt-5" @click="clear">Clear</v-btn>
        </v-toolbar>
      </template>
  </v-data-table>
</template>

<script>
export default {
  name: "AppLogList",

  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  computed: {
    headers() {
      let list = [
        { text: "Date", value: "logDate" },
        { text: "Status", value: "status" },
        { text: "Version", value: "version" },
        { text: "IP", value: "ip" },
        { text: "Notes", value: "notes" }
      ];

      return list;
    }
  },

  methods: {
    clear() {
      if (confirm("Are you sure you want to delete ALL Logs?")) {
        this.$store
          .dispatch("deleteAllTVLog")
          .then(
            response => {
              const notification = {
                show: true,
                result: true,
                message: response
              };

              this.$store.dispatch("showSnackbar", notification);
            },
            error => {
              const notification = {
                show: true,
                result: false,
                message: error
              };

              this.$store.dispatch("showSnackbar", notification);
            }
          )
          .then(() => {
            this.$store.dispatch("getTVLog");
          })
          .then(() => {
            this.$emit("logDeleted");
          });
      }
    }
  }
};
</script>

<style scoped>
.padding-input {
  padding: 0 10px;
}
</style>