<template>
  <div>
    <TVImage title="TV Images" collection="tv-images" :items= tvImages :lines="3" :singleRecord="false"  />
    <AppLogList title="Event Log" :items= tvLog @logDeleted="logDeleted" :key="componentKey" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TVImage from "@/components/admin/TVImage";
import AppLogList from "@/components/admin/AppLogList";

export default {
  name: "PropertyDetails",

  components: {
    TVImage,
    AppLogList
  },

  data() {
    return {
      componentKey: 0
    };
  },

  computed: {
    ...mapGetters(["tvImages", "tvLog"])
  },

  methods: {
    logDeleted() {
      this.componentKey += 1;
    }
  }
};
</script>
